import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCluster } from '../store/actions/clusters/getCluster';
// import { API_URL_ADMIN_USER } from "../constants/API";

export function useGetCluster(clusterId) {
  const selectedCluster = useSelector((state) => state.clusters.cluster);
  const selectedClusterLoading = useSelector((state) => state.clusters.clusterLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    async function clusters() {
      try {
        await dispatch(getCluster(clusterId));
      } catch (err) {
        console.log(err);
      }
    }
    clusters();
  }, []);

  return { selectedCluster, selectedClusterLoading };
}
