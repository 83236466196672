import axios from 'axios';
import toast from 'react-hot-toast';

export async function updateUserOnExternalDb(email, userId, name) {
  const postData = {
    email,
    userId,
    name,
  };

  try {
    const response = await axios.post(
      'https://w3tht52u99.execute-api.us-east-1.amazonaws.com/default/post-registration',
      postData,
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );

    if (response.data && response.data.success === true) {
      return true;
    }
    return false;
  } catch (error) {
    // console.log('err updating user', error);
    return false;
  }
}
