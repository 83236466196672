import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DescriptionIcon from '@mui/icons-material/Description';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { processImageFile } from '../../../../helpers/chunking/processImageFile';
import { processPdfFile } from '../../../../helpers/chunking/processPdfFile';
import { processTextFile } from '../../../../helpers/chunking/processTextFile';
import useResponsive from '../../../../hooks/useResponsive';

const FileSelectionLogic = ({
  chunkedOutputs,
  setSelectedFile,
  setChunkingInProgress,
  onSelect,
  setChunkedOutputs,
  handleDragOver,
  trainingInProgress,
}) => {
  const maxSizeInBytes = 10 * 1024 * 1024; // 10MB in bytes
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'md');
  const chunkSize = 2000;

  const handleFileSelect = async (event) => {
    setChunkingInProgress(true);
    if (event.target.files && event.target.files.length > 2) {
      toast.error('The trial permits the training of up to two documents simultaneously.');
      const files = Array.from(event.target.files).slice(0, 2);
      const validFiles = files.filter((file) => file.size <= maxSizeInBytes);
      // const validFilesWithNewNames = randomizeFileName(validFiles);
      // console.log('Valid : ', validFilesWithNewNames);
      onSelect(validFiles);
    } else {
      const files = Array.from(event.target.files).slice(0, 2);
      const validFiles = files.filter((file) => file.size <= maxSizeInBytes);
      // const validFilesWithNewNames = randomizeFileName(validFiles);
      onSelect(validFiles);

      // Limit chunkedOutputs length to 3
      const updatedChunkedOutputs = chunkedOutputs?.slice(-2);
      setChunkedOutputs(updatedChunkedOutputs);

      // Show toast if more than 3 files are selected
      if (chunkedOutputs && chunkedOutputs?.length >= 2) {
        toast.error('To avoid overloading please keep your file uploads to (2)');
      }

      const inValidFiles = files.filter((file) => file.size >= maxSizeInBytes);
      if (inValidFiles && inValidFiles.length >= 1) {
        inValidFiles.forEach((file) => {
          const fileName = file.name;
          toast.error(
            `${fileName} size is over 10MB (Free tier limit). Please compress your file or divide it to multiple files`
          );
        });
      }

      if (validFiles && validFiles.length >= 1) {
        const promises = validFiles.map(async (file) => {
          if (file.name.endsWith('.txt')) {
            const textFileOutput = await processTextFile(file, chunkSize);
            // console.log('textFileOutput', textFileOutput);
            return textFileOutput;
          }
          if (file.name.endsWith('.pdf')) {
            const pdfFileOutput = await processPdfFile(file, chunkSize);
            return pdfFileOutput;
          }
          if (file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
            // console.log('Docx File');
            return null;
          }
          if (file.name.endsWith('.png') || file.name.endsWith('.jpg')) {
            const imgFileOutput = await processImageFile(file, 1000);
            return imgFileOutput;
          }

          // if (file.name.endsWith('.csv') || file.name.endsWith('.xls')) {
          //   const excelFileOutput = await processExcelFile(file, 1000);
          //   // console.log('excel file output', excelFileOutput);
          //   return excelFileOutput;
          // }
          toast.error('Unsupported file type uploaded.');
          return null;
        });

        const outputs = await Promise.all(promises);

        setChunkedOutputs((prevOutputs) => {
          const filteredOutputs = outputs.filter((output) => output !== null && output.success === true);

          // Remove duplicates by using Set data structure
          const uniqueFilteredOutputs = [...new Set(filteredOutputs)];

          const combinedOutputs = [...prevOutputs, ...uniqueFilteredOutputs];

          // Ensure unique values and limit to the maximum allowed
          const newOutputs = [...new Set(combinedOutputs)].slice(-2);

          return newOutputs;
        });
      }
    }
    setChunkingInProgress(false);
  };

  return (
    <>
      <Box className="justify-between align-center" sx={{ mt: 5, display: isDesktop ? 'flex' : 'block' }}>
        <Box>
          <AutoAwesomeIcon style={{ fontSize: '30px' }} />
          <Typography variant="h5">Upload and Train Documents to Field Reimbursement & Prior Auth</Typography>
          <Typography sx={{ color: 'gray' }}>
            Trial Supported formats : pdf, txt, png, jpg & supports up to 30 uploads
          </Typography>
        </Box>
        <Box className="flex align-center" sx={{ mt: isDesktop ? 0 : 2 }}>
          <Button variant="outlined" sx={{ ml: 2 }} size="large" onClick={() => navigate('/uploaded-documents')}>
            <DescriptionIcon style={{ marginRight: '8px' }} /> Documents
          </Button>
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <label htmlFor="raised-button-file">
          <input
            accept=".pdf, .txt, .png, .jpg"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleFileSelect}
            disabled={trainingInProgress}
          />
          <Button
            variant="raised"
            component="span"
            className="flex justify-center align-center"
            disabled={trainingInProgress}
            sx={{
              width: '100%',
              backgroundColor: '#f7f7f7',
              p: 4,
              border: 'dashed 2.3px black',
              borderRadius: '12px',
            }}
          >
            <Box className="flex column justify-center align-center">
              <img
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/file-upload-v2.svg"
                alt="file-upload-img"
              />
              <Typography sx={{ mt: 2 }} variant="h6">
                Click Here or Drag & Drop a File Here
              </Typography>
              <Typography sx={{ mt: 1, color: 'gray' }}>
                Uploaded documents are private and can only be seen by you
              </Typography>
              <Box
                sx={{
                  mt: 1.5,
                  pl: 0.8,
                  pr: 0.8,
                  pt: 0.4,
                  pb: 0.4,
                  borderRadius: '6px',
                  alignItems: 'center',
                  border: 'solid 1px black',
                }}
              >
                Upload File
              </Box>
            </Box>
          </Button>
        </label>
      </Box>
      <Typography sx={{ color: 'gray', mt: 0.5 }} className="flex justify-end">
        Maximum file size is 10MB
      </Typography>
    </>
  );
};

export default FileSelectionLogic;
