import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import BenefitsList from '../../../assets/data/BenefitsList';
import Footer from '../../../components/Footer';
import { withNoAuth } from '../../../components/HOC/withNoAuth';
import useResponsive from '../../../hooks/useResponsive';
import CustomAppBar from '../../../layouts/basic/CustomAppbar';
import Questions from '../../../components/Questions';
import BCMASadvertisement from './helpers/BCMASadvertisement';
import DemoVideos from './helpers/DemoVideos';
import MedAffairsBenefits from './helpers/MedAffairsBenefits';

function Home() {
  const isDesktop = useResponsive('up', 'md');
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Field Reimbursement & Prior Auth AI</title>
        <meta property="og:title" content="Field Reimbursement & Prior Auth AI Questions" />
        <meta
          property="og:description"
          content="Powerful & secure AI tool designed specifically for the reimbursement & prior auth function. Quickly find the information you need and stay compliant with our continually updated AI"
        />
        <meta
          property="og:image"
          content="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/pacs_ai/priorauthAI_blue.png"
        />
        <meta property="og:url" content="https://reimbursementai.org" />
      </Helmet>
      <Box
        sx={{
          pl: isDesktop ? 12 : 2,
          pr: isDesktop ? 12 : 2,
          height: '650px',
          background: isDesktop
            ? 'linear-gradient(125deg, #ECFCFF 0%, #ECFCFF 40%, #c5dfff calc(40% + 1px), #c5dfff 60%, #8eb7e9 calc(60% + 1px), #8eb7e9 72%, #5b83b2 calc(72% + 1px), #5b83b2 100%);'
            : 'linear-gradient(45deg, #014459 0%, #000000 100%),radial-gradient(100% 225% at 100% 0%, #014459 0%, #000000 100%),linear-gradient(225deg, #707070 0%, #000000 100%),linear-gradient(135deg, #CDFFEB 10%, #CDFFEB 35%, #009F9D 35%, #ffffff 60%, #07456F 60%, #07456F 67%, #0F0A3C 67%, #0F0A3C 100%)',
          backgroundSize: 'cover',
        }}
      >
        <CustomAppBar />
        <Container sx={{ maxWidth: isDesktop ? '1305px' : '100%' }} maxWidth={false}>
          <Box
            sx={{
              pt: isDesktop ? 9 : 5,
            }}
          >
            <Typography
              sx={{
                color: isDesktop ? '' : 'white',
                lineHeight: isDesktop ? '70px' : '35px',
                width: '100%',
                fontSize: isDesktop ? '60px' : '33px',
              }}
              className={isDesktop ? 'animate-charcter' : ''}
            >
              Field Reimbursement & <br />
              Prior Auth AI
            </Typography>

            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  pt: isDesktop ? 1 : 2,
                  color: isDesktop ? '#042249' : 'white',
                  width: isDesktop ? '75%' : '100%',
                  fontWeight: 600,
                  fontSize: isDesktop ? '20px' : '17px',
                }}
              >
                Powerful & secure AI tool designed specifically for the reimbursement & prior auth function. Quickly
                find the information you need and stay compliant with our continually updated AI
              </Typography>
            </Box>

            <Box sx={{ mt: 4 }}>
              <Button
                style={{
                  fontSize: '18px',
                  marginRight: '15px',
                  backgroundColor: '#3771bb',
                  padding: '25px 35px',
                  color: 'white',
                  borderRadius: '6px',
                  borderBottom: '4px solid #1a3e6c',
                  borderTop: '0',
                  transition: 'all 0.1s ease-in-out',
                }}
                fullWidth={!isDesktop}
                size="large"
                onClick={(e) => navigate('/demo')}
              >
                Request a Demo
              </Button>

              <Button
                component={Link}
                className={isDesktop ? 'customDullWelcomeButton' : ''}
                to="/login"
                fullWidth={!isDesktop}
                sx={{
                  border: isDesktop ? 'solid black 2px' : 'solid white 2px',
                  backgroundColor: 'transparent',
                  fontSize: '18px',
                  color: isDesktop ? 'black' : 'white',
                  '&:hover': { backgroundColor: 'transparent' },
                  mt: isDesktop ? 0 : 2,
                }}
                size="large"
              >
                Get Started Today
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{ pt: 10, pb: 5 }}>
        <Container sx={{ maxWidth: isDesktop ? '1305px' : '100%' }} maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item md={6} sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h3">What is Field Reimbursement & Prior Auth AI?</Typography>
              <Typography sx={{ fontWeight: 400, mt: 1.7 }} variant="h6">
                The first-ever cloud-based machine learning tool that can be custom trained on your internal data
                (clinical studies, SOPs, articles, etc.). No more wasted time looking through shared drives and folders.
                Information is now at your fingertips! Stop wasting countless hours looking for the information you need
                on market access, prior auth, and your own clinical data. Our Reimbursement AI tool will save incredible
                time so that you can use resources more efficiently.
              </Typography>
            </Grid>
            <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/med+affairs+ai+settings.svg"
                alt="ai"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ pt: 10, pb: 10 }}>
        <Container sx={{ maxWidth: isDesktop ? '1305px' : '100%' }} maxWidth={false}>
          <DemoVideos />
        </Container>
      </Box>

      <Box
        sx={{
          backgroundColor: '#032D3A;',
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23033240' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23023646' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23023b4c' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23023f53' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23014459' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23023f53' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23023b4c' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23023646' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23033240' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23032D3A' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");`,
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          pt: 15,
          pb: 15,
        }}
      >
        <MedAffairsBenefits list={BenefitsList} title={'How it Works?'} />
      </Box>

      <Container sx={{ maxWidth: isDesktop ? '1305px' : '100%' }} maxWidth={false}>
        <Box sx={{ pt: 10, pb: 10 }}>
          <Questions />
        </Box>
      </Container>

      <Box sx={{ pt: 10, pb: 10 }}>
        <Container sx={{ maxWidth: isDesktop ? '1305px' : '100%' }} maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Typography variant="h3">Information when you need it. Now.</Typography>
              <Box sx={{ pt: 4 }}>
                <ul>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Stay ahead of the competition
                    </Typography>
                  </li>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Save time & money
                    </Typography>
                  </li>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Streamline resources
                    </Typography>
                  </li>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Provide accurate & timely information in seconds
                    </Typography>
                  </li>
                  <li style={{ marginTop: '10px', display: 'flex', alignItems: 'start' }}>
                    <Typography variant="h3">•</Typography>
                    <Typography sx={{ fontWeight: 400, mt: 1.3, ml: 2 }} variant="h6">
                      Minimize Risk & Improve Compliance
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Grid>
            <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src="https://images.ctfassets.net/ohqd46y4hkj2/70H1NFhcqy15aTI3roMzBe/a869748e3d916ac2c405e32d3a0bafe5/Blue_Minimalist_Artificial_Intelligence_Technology_Logo__1_.svg"
                alt="ai-img"
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="container"
              size="large"
              style={{
                marginTop: '10px',
                fontSize: '18px',
                marginRight: '15px',
                backgroundColor: '#3771bb',
                padding: '20px 30px',
                color: 'white',
                borderRadius: '6px',
                borderBottom: '4px solid #1a3e6c',
                borderTop: '0',
                transition: 'all 0.1s ease-in-out',
              }}
              onClick={(e) => navigate('/demo')}
            >
              Book a demo today
            </Button>
          </Box>
        </Container>
      </Box>

      <Box>
        <Container sx={{ maxWidth: isDesktop ? '1305px' : '100%' }} maxWidth={false}>
          <BCMASadvertisement />
        </Container>
      </Box>

      <Footer />
    </>
  );
}

export default withNoAuth(Home);
