import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui

import Skeleton from '@mui/material/Skeleton';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';

import { Avatar, Box, Drawer, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// mock
// hooks
import { formatString } from '../../helpers/formatString';
import useResponsive from '../../hooks/useResponsive';

// components
import NavSection from '../../components/nav-section';
import Scrollbar from '../../components/scrollbar';
import { clearALLStates } from '../../store/actions/clear';

import Logo from '../../components/logo/Logo';
import { useGetExternalUser } from '../../hooks/useGetExternalUser';
import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  backgroundColor: '#4183d7',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { externalDbUser, externalUserLoading } = useGetExternalUser();

  // console.log('externalDbUser', externalDbUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = async () => {
    try {
      await Auth.signOut();
      dispatch(clearALLStates());
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <StyledAccount>
          <Avatar
            src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/icons/neutral-glasses-person.jpg"
            alt="photoURL"
          />

          <Box sx={{ ml: 2 }}>
            {externalDbUser && externalDbUser.name && externalDbUser.name.length >= 1 ? (
              <>
                <Typography variant="subtitle2">{formatString(externalDbUser?.name || '', 15)}</Typography>

                <Typography variant="body2" sx={{ color: '#e1e1e1' }}>
                  {formatString(externalDbUser?.email || '', 17)}
                </Typography>
              </>
            ) : (
              <>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '140px' }} />
              </>
            )}
          </Box>
        </StyledAccount>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <div className="mt-auto">
            <div className="py-2.5 px-7">
              <p className="inline-flex items-center gap-x-2 text-xs" style={{ color: '#2563eb' }}>
                {externalUserLoading ? (
                  <>Loading...</>
                ) : (
                  <>Trial expires on {externalDbUser && externalDbUser.access_expired}</>
                )}
              </p>
            </div>
          </div>

          <div className="p-2 border-t border-gray-200 w-full" style={{ marginTop: '5px' }}>
            <button
              className="w-full flex justify-between items-center gap-x-3 py-2 px-3 text-sm text-slate-700 rounded-lg hover:bg-gray-100"
              type="submit"
              onClick={logout}
            >
              Sign out
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                <polyline points="10 17 15 12 10 7" />
                <line x1="15" x2="3" y1="12" y2="12" />
              </svg>
            </button>
          </div>

          <div>
            <a href="https://medicalaffairsspecialist.org/policy/terms-and-conditions" style={{ fontSize: '10px' }}>
              Terms & Conditions
            </a>
          </div>

          {/* <Box
            component="img"
            src="/assets/images/products/soon.svg"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          /> */}

          {/* <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Accreditation Status
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Check your next re-accreditation
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Reaccreditation
          </Button> */}
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
