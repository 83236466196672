import axios from 'axios';
import { toast } from 'react-hot-toast';
import { EXTERNAL_DB_USER } from '../../types';
import { UN_AUTH_USER } from '../../API';

export async function getUserInfo(email, dispatch) {
  try {
    const response = await axios.get('https://w3tht52u99.execute-api.us-east-1.amazonaws.com/default/login', {
      params: {
        email,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const userInfo = response.data;
      dispatch({
        type: EXTERNAL_DB_USER,
        payload: userInfo.user,
      });
      return { success: true, userInfo: userInfo.user };
    }

    return { success: false, userInfo: null };
  } catch (error) {
    // console.log('error getting user info', error);
    return { success: false, message: error.response.data.message };
  }
}
