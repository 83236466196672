import axios from 'axios';
import { Auth } from 'aws-amplify';
import { GET_COMPANY_DOCUMENTS } from '../../types';

export const getTrainedDocuments = (page, filters) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  const userid = user?.username;

  try {
    const params = {
      email,
      userid,
      page,
      payload: JSON.stringify(filters),
    };

    const res = await axios.get('https://w3tht52u99.execute-api.us-east-1.amazonaws.com/default/documents', {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params,
    });

    if (res) {
      dispatch({
        type: GET_COMPANY_DOCUMENTS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COMPANY_DOCUMENTS,
      payload: [],
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
