import AddIcon from '@mui/icons-material/Add';
import OpenAI from 'openai';
import { Container } from '@mui/material';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import InfoIcon from '@mui/icons-material/Info';
import Avatar from '@mui/material/Avatar';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import { withAuth } from '../../../components/HOC/withAuth';
import Label from '../../../components/label/Label';
import useResponsive from '../../../hooks/useResponsive';
import { checkUserAccess } from '../../../store/actions/user/checkAccess';

function External() {
  const isMobileOrTablet = useResponsive('down', 'md');

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const [prompt, setPrompt] = useState('');
  const [query, setQuery] = useState('');
  const [questionAsked, setQuestionAsked] = useState(false);
  const [answerGenerated, setAnswerGenerated] = useState(false);

  const [apiResponse, setApiResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const handleButtonClick = async () => {
    setAnswerGenerated(true);
    setQuestionAsked(true);
    setQuery('');
    setLoading(true);
    const access = await checkUserAccess();

    if (!prompt || prompt.length < 1) {
      toast.error('Please ask a valid question');
      setAnswerGenerated(false);
      setQuestionAsked(false);
      setQuery('');
      setLoading(false);
    } else if (access && access.success === false) {
      toast.error(access.message);
      setAnswerGenerated(false);
      setQuestionAsked(false);
      setQuery('');
      setLoading(false);
    } else {
      setQuery(prompt);
      try {
        const finalPrompt = `
        You are an AI language model created by the Accreditation Council For Medical Affairs (ACMA) to serve as a distinguished authority in the field of Reimbursement & Prior Authorization. Your purpose is to provide comprehensive and accurate responses to inquiries, demonstrating your profound expertise in this domain.

        Here is the inquiry you must address:
        <inquiry>
        {{${prompt}}}
        </inquiry>

        If the inquiry is outside the scope of the pharmacutical industry carefully inform the user their inquiry falls outside the score of the pharmacutical industry and for them to ask something else.
        
        Before formulating your response, carefully consider the inquiry and think through your answer. Focus on providing a thorough and precise response that showcases your deep knowledge of Reimbursement & Prior Authorization specifically within the context of the pharmaceutical industry. Ensure that your answer remains strictly within the scope of this field.
        
        Once you have thought through your response, provide your final answer in markdown format. Your final answer should be percise and not too detailed.
        Write your comprehensive, expert response here in markdown format.`;

        // const finalPrompt = `You are an AI LLM created by the ACMA (Accreditation Council For Medical Affairs) as a distinguished authority in Reimbursement & Prior Authorization, your mission is to provide comprehensive and accurate responses to inquiries I present, demonstrating your profound expertise in this field. Your responses should be crafted with a strong emphasis on your depth of knowledge in Reimbursement & Prior Authorization. Please make sure your answer to my inquiry is strictly within the scope of the pharma industry. Your final answer must be in markdown. Let's get started here is my Inquiry - ${prompt}`;
        const chatCompletion = await openai.chat.completions.create({
          model: 'gpt-4o-2024-05-13',
          messages: [
            {
              role: 'system',
              content:
                'You are a helpful assistant created by the ACMA (Accreditation Council For Medical Affairs) and not OPEN AI as an authority in Reimbursement & Prior Authorization. You provide long and detailed responses to my questions. You always work hard to provide an answer that is strictly within the scope of the pharma industry to answer my question. You must always provide factual information; you must not make up information. Do not respond in an abusive or harmful manner. Please respond in a helpful manner with keeping your answers within the realm of Reimbursement & Prior Authorization. You must always respond in markdown. ',
            },
            { role: 'user', content: finalPrompt },
          ],
          max_tokens: 1000,
          stream: true,
        });

        setLoading(false);

        let accumulatedResponse = '';
        // eslint-disable-next-line no-restricted-syntax
        for await (const chunk of chatCompletion) {
          if (chunk.choices[0].delta.content !== undefined) {
            accumulatedResponse += chunk.choices[0].delta.content;
            setApiResponse(accumulatedResponse);
          }
        }
        setAnswerGenerated(false);
        setPrompt('');
      } catch (e) {
        toast.error('Something is going wrong, Please try again.');
        setLoading(false);
      }
    }
  };

  const clearQuestions = () => {
    setQuestionAsked(false);
    setQuery('');
    setApiResponse('');
  };

  return (
    <Container>
      {questionAsked ? (
        <>
          <div
            style={{
              height: isMobileOrTablet ? '400px' : '330px',
              marginTop: isMobileOrTablet ? '10px' : '100px',
              backgroundColor: '#1d5296',
              backgroundPosition: '50%,50%,50%,0 16px',
              backgroundRepeat: 'repeat,no-repeat,no-repeat,repeat',
              backgroundSize: '100px,180%,auto,32px',
              backgroundImage:
                'url(),url(https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/hero-flair.svg),radial-gradient(42% 47% at 50% 50%,rgba(12,23,62,0) 0,#021f28 100%),url(https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/little-grid.svg)',
            }}
            className="w-full text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8"
          >
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: '#1b4c89',
                height: isMobileOrTablet ? '80px' : '50px',
                textAlign: 'left',
                paddingLeft: '20px',
                color: 'white',
              }}
              className="flex items-center"
            >
              <InfoIcon />
              <h4 style={{ paddingLeft: '10px' }}>The following is a trial version of reimbursement AI</h4>
            </div>
            <div
              style={{
                paddingLeft: isMobileOrTablet ? '20px' : '50px',
                paddingRight: isMobileOrTablet ? '20px' : '50px',
                paddingTop: '25px',
                textAlign: 'left',
              }}
            >
              <Label style={{ backgroundColor: 'white', color: 'black' }}>External Search</Label>
              <h5 className="mb-2 text-5xl font-bold text-white mt-3">Ask me anything...</h5>

              <footer style={{ bottom: 0, maxWidth: '68rem', paddingTop: '12px' }} className="mx-auto sticky z-10">
                <div className="relative">
                  <textarea
                    className="p-4 pb-12 block w-full rounded-lg text-sm"
                    style={{ border: 'solid 1px gray' }}
                    placeholder="Ask me anything..."
                    disabled={answerGenerated}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                  />

                  <div className="absolute bottom-px inset-x-px p-2 rounded-b-md bg-white">
                    <div className="flex justify-between items-center">
                      <div>
                        <button
                          type="button"
                          onClick={clearQuestions}
                          disabled={answerGenerated}
                          style={{ backgroundColor: answerGenerated ? '#c0bebe' : 'gray' }}
                          className="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-lg text-white"
                        >
                          <AddIcon />
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={handleButtonClick}
                          disabled={answerGenerated}
                          style={{ backgroundColor: answerGenerated ? 'gray' : '#2563eb' }}
                          className="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-lg text-white"
                        >
                          <svg
                            className="flex-shrink-0 size-3.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>

          <div
            style={{
              marginTop: '20px',
              padding: isMobileOrTablet ? '20px' : '50px',
            }}
            className="w-full bg-white border border-gray-200 rounded-lg shadow sm:p-8"
          >
            <p className="text-3xl font-bold" style={{ marginTop: '10px', color: '#4183d7' }}>
              Search Result
            </p>
            <h4 className="text-lg" style={{ marginBottom: '10px', marginTop: '10px' }}>
              {query || ''}
            </h4>
            <hr />
            <div>
              <div style={{ marginBottom: '0.375rem', fontSize: '0.875rem', color: '#374151', marginTop: '20px' }}>
                {loading ? 'Loading...' : <ReactMarkdown>{apiResponse}</ReactMarkdown> || ''}
              </div>
              {answerGenerated ? (
                <></>
              ) : (
                <div
                  className="justify-between items-center"
                  style={{ marginTop: '20px', display: isMobileOrTablet ? '' : 'flex' }}
                >
                  <div>
                    <Label style={{ backgroundColor: '#4183d7', color: 'white' }}>External Search</Label>
                  </div>
                  <div>
                    <p style={{ fontSize: '12px' }}>Consider checking important information.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              height: isMobileOrTablet ? '700px' : '580px',
              marginTop: isMobileOrTablet ? '5px' : '120px',
              backgroundColor: '#1d5296',
              backgroundPosition: '50%,50%,50%,0 16px',
              backgroundRepeat: 'repeat,no-repeat,no-repeat,repeat',
              backgroundSize: '100px,180%,auto,32px',
              backgroundImage:
                'url(),url(https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/hero-flair.svg),radial-gradient(42% 47% at 50% 50%,rgba(12,23,62,0) 0,#021f28 100%),url(https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/little-grid.svg)',
            }}
            className="w-full text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8"
          >
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: '#1b4c89',
                height: isMobileOrTablet ? '80px' : '50px',
                textAlign: 'left',
                paddingLeft: '20px',
                color: 'white',
              }}
              className="flex items-center"
            >
              <InfoIcon />
              <h4 style={{ paddingLeft: '10px' }}>The following is a trial version of reimbursement AI</h4>{' '}
              {isMobileOrTablet ? (
                <></>
              ) : (
                <>
                  <Label style={{ backgroundColor: 'white', color: 'black', marginLeft: '8px' }}>BETA</Label>
                </>
              )}
            </div>
            <div style={{ padding: isMobileOrTablet ? '30px' : '50px', textAlign: 'left' }}>
              <Label style={{ backgroundColor: 'white', color: 'black' }}>External Search</Label>
              <h5 className="mb-2 text-5xl font-bold text-white mt-3">Ask me anything...</h5>

              <div className="space-y-1.5">
                {isMobileOrTablet ? (
                  <>
                    <p className="mb-1.5 text-white">
                      For the best answers please keep your questions within the reimbursement & prior authorization
                      space
                    </p>
                  </>
                ) : (
                  <>
                    <p className="mb-1.5 text-white">
                      Stay up to date and get real time field reimbursement & prior auth insights when you need it. For
                      the best answers please keep your questions within the reimbursement & prior authorization space
                    </p>
                  </>
                )}

                <div className="flex" style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <Avatar sx={{ backgroundColor: 'white' }}>
                    <BubbleChartIcon style={{ color: '#2563eb' }} />
                  </Avatar>
                  <p className="text-md text-white" style={{ marginTop: '10px', paddingLeft: '8px' }}>
                    You can ask....
                  </p>
                </div>

                <ul className="list-disc list-outside space-y-1.5 ps-3.5">
                  <li className="text-slate-50">what is the icd10 code D48.1 used for?</li>

                  <li className="text-slate-50">
                    explain the concept of "bundled payments" in healthcare reimbursement?
                  </li>

                  <li className="text-slate-50">what happens if a prior authorization is denied?</li>
                </ul>
              </div>
            </div>
            <div>
              <footer
                style={{ bottom: 0, maxWidth: '68rem' }}
                className="mx-auto sticky z-10 pt-2 pb-4 sm:pt-4 sm:pb-6 px-4 sm:px-6 lg:px-0"
              >
                <div className="relative">
                  <textarea
                    className="p-4 pb-12 block w-full rounded-lg text-sm"
                    style={{ border: 'solid 1px gray' }}
                    placeholder="Ask me anything..."
                    disabled={answerGenerated}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                  />

                  <div className="absolute bottom-px inset-x-px p-2 rounded-b-md bg-white">
                    <div className="flex justify-end items-center">
                      <div>
                        <button
                          type="button"
                          onClick={handleButtonClick}
                          disabled={answerGenerated}
                          style={{ backgroundColor: answerGenerated ? 'gray' : '#2563eb' }}
                          className="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-lg text-white"
                        >
                          <svg
                            className="flex-shrink-0 size-3.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </>
      )}

      {/* <div className="relative" style={{ paddingTop: isMobileOrTablet ? '0px' : '70px' }}>
        <div className="max-w-4xl mx-auto text-center">
          <h1 style={{ marginTop: '14px' }} className="text-3xl font-bold text-gray-800 sm:text-4xl">
            Reimbursement AI
          </h1>
          <p className="mt-1 text-gray-600">Real-time Field Reimbursement & Prior Auth Insights When You Need It</p>
        </div>

        {questionAsked ? (
          <>
            <div
              className="max-w-4xl mx-auto"
              style={{ height: '604px', maxHeight: '604px', overflowY: 'scroll', scrollbarWidth: 'none' }}
            >
              <ul className="mt-16 space-y-5">
                <li
                  style={{
                    maxWidth: '2xl',
                    marginLeft: 'auto',
                    display: 'flex',
                    justifyContent: 'end',
                    gap: '0.5rem',
                    gapX: '2',
                    padding: '20px',
                    '@media (min-width: 640px)': { gapX: '4' },
                  }}
                >
                  <div style={{ flexGrow: '1', textAlign: 'end', marginBottom: '3' }}>
                    <div
                      style={{
                        display: 'inline-block',
                        backgroundColor: '#2563EB',
                        borderRadius: '0.5rem',
                        padding: '1rem',
                        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
                      }}
                    >
                      <p style={{ fontSize: '0.875rem', color: '#ffffff' }}>{query}</p>
                    </div>
                  </div>

                  <span
                    style={{
                      flexShrink: '0',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '38px',
                      height: '38px',
                      borderRadius: '50%',
                      backgroundColor: '#4B5563',
                    }}
                  >
                    <span style={{ fontSize: '0.875rem', fontWeight: '500', color: '#ffffff', lineHeight: '1' }}>
                      <Avatar
                        alt="neutral-avatar"
                        src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/icons/neutral-glasses-person.jpg"
                      />
                    </span>
                  </span>
                </li>

                <li
                  style={{
                    marginBottom: '4rem',
                    display: 'flex',
                    padding: '20px',
                    gap: '0.5rem',
                    '@media (min-width: 640px)': { gap: '1rem' },
                  }}
                >
                  <Avatar sx={{ backgroundColor: '#2563eb' }}>
                    <BubbleChartIcon />
                  </Avatar>

                  <div
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid #E5E7EB',
                      borderRadius: '0.375rem',
                      padding: '1rem',
                      marginTop: '0.375rem',
                    }}
                  >
                    <div style={{ marginBottom: '0.375rem', fontSize: '0.875rem', color: '#374151' }}>
                      {loading ? 'Loading...' : <ReactMarkdown>{apiResponse}</ReactMarkdown> || ''}
                    </div>
                    {answerGenerated ? (
                      <></>
                    ) : (
                      <div className="flex justify-between items-center">
                        <div>
                          <Label>External Search</Label>
                        </div>
                        <div>
                          <p style={{ fontSize: '12px' }}>AI can make mistakes. Please double-check responses.</p>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="max-w-4xl mx-auto" style={{ height: '540px', maxHeight: '540px' }}>
              <ul className="mt-16 space-y-5">
                <li className="flex gap-x-2 sm:gap-x-4">
                  <Avatar sx={{ backgroundColor: '#2563eb' }}>
                    <BubbleChartIcon />
                  </Avatar>

                  <div className="w-full bg-white border border-gray-200 rounded-lg p-4 space-y-3">
                    <Label variant="filled" color="primary">
                      External Data
                    </Label>
                    <h2 className="font-medium text-gray-800">Welcome, how can I help?</h2>
                    <div className="space-y-1.5">
                      <p className="mb-1.5 text-sm text-gray-800">
                        Here you can ask any questions related to the filed reimbursement and prior authorization space.
                        The external data model has been trained with the largest compendium of filed reimbursement and
                        prior authorization data. Please keep your questions within the industry for the best answers.
                      </p>

                      <p className="mb-1.5 text-sm text-gray-800">You can ask...</p>
                      <ul className="mt-1.5 list-disc list-outside space-y-1.5 ps-3.5">
                        <li className="text-sm text-gray-800">what is the idc10 code D48.1 used for?</li>

                        <li className="text-sm text-gray-800">
                          Can you explain the concept of "bundled payments" in healthcare reimbursement?
                        </li>

                        <li className="text-sm text-gray-800">What happens if a prior authorization is denied?</li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}

        <footer
          style={{ bottom: 0 }}
          className="max-w-4xl mx-auto sticky z-10 pt-2 pb-4 sm:pt-4 sm:pb-6 px-4 sm:px-6 lg:px-0"
        >
          <div className="relative">
            <textarea
              className="p-4 pb-12 block w-full rounded-lg text-sm"
              style={{ border: 'solid 1px gray' }}
              placeholder="Ask me anything..."
              disabled={answerGenerated}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />

            <div className="absolute bottom-px inset-x-px p-2 rounded-b-md bg-white">
              <div className="flex justify-between items-center">
                <div>
                  <button
                    type="button"
                    onClick={clearQuestions}
                    disabled={answerGenerated}
                    style={{ backgroundColor: answerGenerated ? '#c0bebe' : 'gray' }}
                    className="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-lg text-white"
                  >
                    <AddIcon />
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={handleButtonClick}
                    disabled={answerGenerated}
                    style={{ backgroundColor: answerGenerated ? 'gray' : '#2563eb' }}
                    className="inline-flex flex-shrink-0 justify-center items-center size-8 rounded-lg text-white"
                  >
                    <svg
                      className="flex-shrink-0 size-3.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div> */}
    </Container>
  );
}

export default withAuth(External);
