import axios from 'axios';
import { Auth } from 'aws-amplify';

export const checkDocumentBeforeUpload = async (fileOutputs) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user?.attributes.email;
  const userid = user?.username;

  const postData = {
    userid,
    email,
    files: fileOutputs,
  };

  try {
    const res = await axios.post(
      'https://w3tht52u99.execute-api.us-east-1.amazonaws.com/default/document-entry-check',
      postData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      }
    );
    return res.data;
  } catch (error) {
    if (error.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', error.message);
    }
    throw error;
  }
};
