import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClusters } from '../store/actions/clusters/getAllClusters';
// import { API_URL_ADMIN_USER } from "../constants/API";

export function useGetAllClusters() {
  const allClusters = useSelector((state) => state.clusters.allClusters);
  const allClustersLoading = useSelector((state) => state.clusters.allClustersLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    async function clusters() {
      try {
        await dispatch(getAllClusters());
      } catch (err) {
        console.log(err);
      }
    }
    clusters();
  }, []);

  return { allClusters, allClustersLoading };
}
