export const UN_AUTH_API = 'https://nen7zkpix9.execute-api.us-east-1.amazonaws.com/default';
export const MAIN_API = 'https://wyvoh770h9.execute-api.us-east-1.amazonaws.com/default';
export const UPLOAD_DOCUMENT_API = 'https://y5e33d8ln3.execute-api.us-east-1.amazonaws.com/default';
export const COMPLIANCE_API = 'https://b4o1jcgola.execute-api.us-east-1.amazonaws.com/default/';

export const NEW_MAIN_API = 'https://w3tht52u99.execute-api.us-east-1.amazonaws.com/default';
export const NEW_FILE_HANDLER_API = 'https://r9jisaukr9.execute-api.us-east-1.amazonaws.com/default';

export const UN_AUTH_USER = UN_AUTH_API.concat('/user');
export const GET_AUTH_USER_API = MAIN_API.concat('/user');
export const SEARCH_API = MAIN_API.concat('/search');
export const TRAIN_API = MAIN_API.concat('/train');

export const UPLOAD_DOCUMENT_TO_EXTERNAL_DB_API = MAIN_API.concat('/documents');
export const GET_DOCUMENT_FROM_EXTERNAL_DB_API = MAIN_API.concat('/documents');
export const DELETE_DOCUMENTS_FROM_EXTERNAL_DB_API = MAIN_API.concat('/documents');

export const GET_DOCUMENT_FROM_EXTERNAL_DB_API_FILTERED = MAIN_API.concat('/documents/filtered');
export const GET_DOCUMENT_UPLOAD_URL = UPLOAD_DOCUMENT_API.concat('/upload-url');
export const DOWNLOAD_FILE = UPLOAD_DOCUMENT_API.concat('/download');
export const COMPANY_USERS_API = MAIN_API.concat('/company-users');
export const DELETE_S3_DOCUMENT_API = UPLOAD_DOCUMENT_API.concat('/delete-documents');
export const UPDATE_AUTH_USER_API = MAIN_API.concat('/user');

export const GET_POLICY_REQUIREMENTS = COMPLIANCE_API.concat('/requirements');

export const TRAIN_V2 = NEW_MAIN_API.concat('/train-v2');
export const REMOVE_PINECONE_TRAINING = NEW_MAIN_API.concat('/pinecone-remove-training');
export const EXTERNAL_DB_UPLOAD = NEW_MAIN_API.concat('/document-entry');
export const DELETE_EXTERNAL_DB_API = NEW_MAIN_API.concat('/remove-document');

export const NEW_UPLOAD_URL = NEW_FILE_HANDLER_API.concat('/upload-url');
export const NEW_DOWNLOAD_URL = NEW_FILE_HANDLER_API.concat('/download');
export const NEW_GET_ALL_CLUSTERS = NEW_MAIN_API.concat('/clusters');
export const NEW_GET_CLUSTER = NEW_MAIN_API.concat('/cluster');
export const NEW_CREATE_CLUSTER = NEW_MAIN_API.concat('/cluster');
export const NEW_DELETE_CLUSTER = NEW_MAIN_API.concat('/cluster');
export const NEW_UPDATE_CLUSTER = NEW_MAIN_API.concat('/cluster');
export const NEW_CHECK_USER_ACCESS = NEW_MAIN_API.concat('/user/user-access');
