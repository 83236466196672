import { Helmet } from 'react-helmet-async';
import HubspotForm from 'react-hubspot-form';
import { Box, Container, Grid, Typography } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import CustomAppBar from '../../../layouts/basic/CustomAppbar';
import { withNoAuth } from '../../../components/HOC/withNoAuth';
import Footer from '../../../components/Footer';

function Demo() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title>Request a Demo</title>
        <meta property="og:title" content="Field Reimbursement & Prior Auth  AI Request a Demo" />
        <meta
          property="og:description"
          content="Experience the Future: Request a Field Reimbursement & Prior Auth AI Demo today. Witness the revolution in medical affairs through our advanced AI tool, leveraging cutting-edge machine learning and extensive medical data for streamlined processes. Empower your decisions with custom training on internal materials. Embrace efficiency and data-driven excellence, bidding farewell to scattered folders. Discover the smarter choice for medical affairs with Field Reimbursement & Prior Auth AI"
        />
        <meta
          property="og:image"
          content="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/ai/medaffairsai.png"
        />
        <meta property="og:url" content="https://medaffairsai.org/questions" />
      </Helmet>
      <Box
        sx={{
          m: isDesktop ? 2 : 0,
          borderRadius: isDesktop ? '15px' : '0px',
          pl: isDesktop ? 12 : 2,
          pr: isDesktop ? 12 : 2,
          height: isDesktop ? '600px' : '500px',
          background: `linear-gradient(45deg, #014459 0%, #000000 100%),radial-gradient(100% 225% at 100% 0%, #014459 0%, #000000 100%),linear-gradient(225deg, #707070 0%, #000000 100%),linear-gradient(135deg, #CDFFEB 10%, #CDFFEB 35%, #009F9D 35%, #ffffff 60%, #07456F 60%, #07456F 67%, #0F0A3C 67%, #0F0A3C 100%)`,
          backgroundSize: 'cover',
        }}
      >
        <CustomAppBar />
        <Container maxWidth="" sx={{ padding: '0px' }}>
          <Box
            sx={{
              pt: 9,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ width: '100%', color: 'white' }} variant="h1">
              Request A Demo
            </Typography>
            <Typography variant="h4" sx={{ pt: 3, color: 'white', width: '75%', fontWeight: 500 }}>
              Using Field Reimbursement & Prior Auth AI can help your company save time, money and help reduce risk
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box sx={{ pt: 10, pb: 10 }}>
        <Container>
          <Box sx={{ pt: 2 }}>
            <Box sx={{ pb: 2 }}>
              <Typography variant="h3">Request a Demo</Typography>
              <Typography>
                Request a demo to experience firsthand how our Field Reimbursement & Prior Auth AI can empower your team
              </Typography>
            </Box>
            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item md={10}>
                <HubspotForm
                  portalId="22711473"
                  formId="a993de43-982a-4e0b-b3cd-585bfd81724f"
                  onSubmit={() => console.log('Submit!')}
                  onReady={(form) => console.log('Form ready!')}
                  loading={<div>Loading...</div>}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Footer />
    </>
  );
}

export default withNoAuth(Demo);
