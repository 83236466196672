import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import useResponsive from '../../../../hooks/useResponsive';

export default function BCMASadvertisement() {
  const isDesktop = useResponsive('up', 'md');
  return (
    <Box>
      <Card sx={{ background: 'linear-gradient(135deg,  #0c3a57, #014459)', color: 'white' }}>
        <CardContent>
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                className="bouncing-image"
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/pacs_ai/PACS-Seal.png"
                alt="pacs-seal"
                width="50%"
              />
            </Grid>
            <Grid item md={6} sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h3">
                Become a Prior Authorization Certified Specialist (PACS
                <span style={{ fontSize: '16px', paddingBottom: '10px' }}>™</span>)
              </Typography>

              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Earn the recognition of achieving the standard of excellence among field reimbursement professionals
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button
                  style={{
                    fontSize: '18px',
                    marginRight: '15px',
                    backgroundColor: '#3771bb',
                    padding: '20px 30px',
                    color: 'white',
                    borderRadius: '6px',
                    borderBottom: '4px solid #1a3e6c',
                    borderTop: '0',
                    transition: 'all 0.1s ease-in-out',
                  }}
                  target="_blank"
                  fullWidth={!isDesktop}
                  size="large"
                  href="https://www.priorauthtraining.org/"
                >
                  Learn more
                </Button>

                <Button
                  fullWidth={!isDesktop}
                  variant="container"
                  size="large"
                  style={{
                    fontSize: '18px',
                    marginRight: '15px',
                    backgroundColor: 'white',
                    padding: '20px 30px',
                    color: 'black',
                    borderRadius: '6px',
                    borderBottom: '4px solid #1a3e6c',
                    borderTop: '0',
                    transition: 'all 0.1s ease-in-out',
                  }}
                  target="_blank"
                  href="https://www.acmamarketplace.org/certification/pacs"
                >
                  Enroll Today
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
